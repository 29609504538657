import React from 'react';
import { Button } from 'semantic-ui-react';

const IconButton = (props: {
  icon: string;
  disabled: boolean;
  onClick: any;
  color: string;
}) => {
  return (
    <Button
      basic
      color={props.color}
      icon={props.icon}
      disabled={props.disabled}
      onClick={props.onClick}
    />
  );
};

export default IconButton;
