import React from 'react';
import { List, Message } from 'semantic-ui-react';
import { ErrorListProps } from 'react-jsonschema-form';

const ErrorList = ({ errors }: ErrorListProps) => {
  return (
    <Message color="red">
      <List bulleted>
        {errors.map((error, i: number) => {
          return <List.Item key={i}>{error.stack}</List.Item>;
        })}
      </List>
    </Message>
  );
};

export default ErrorList;
