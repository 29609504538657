import React from 'react';

import { Button } from 'semantic-ui-react';

import { AddButtonProps } from 'react-jsonschema-form';

const AddButton: React.FC<AddButtonProps> = props => (
  <Button basic color="orange" onClick={props.onClick}>
    Add Item
  </Button>
);

export default AddButton;
