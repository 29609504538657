import * as React from 'react';
import { Link } from 'gatsby';
import * as TOML from '@iarna/toml';
import { StaticQuery, graphql } from 'gatsby';
import { Dropdown, Tab, Form as SUIForm, Input } from 'semantic-ui-react';

import Highlighter from '../mdx_blocks/highlighter';

import { withTheme } from 'react-jsonschema-form';
import { Theme as CustomTheme } from './react_json_schema_form_theme';
import { versionNumberFromDocPath } from '../../utils';

const Form = withTheme(CustomTheme);

export class SalvusConfigBuilder extends React.Component<
  {},
  {
    active_tab_index: number;
    salvus_version?: string;
    site_type?: string;
    form_data: object;
    example_site_name: string;
  }
> {
  constructor(props: {}) {
    super(props);
    this.state = {
      active_tab_index: 0,
      salvus_version: undefined,
      site_type: undefined,
      form_data: {},
      example_site_name: 'my_site',
    };
  }
  onChange = (d: { formData: object }) => {
    const state = { ...this.state };
    state.form_data = d.formData;
    this.setState(state);
  };

  customValidate = (formData: object, errors: object) => {
    if (formData.max_ranks < formData.default_ranks) {
      errors.max_ranks.addError('Must not be smaller than the default ranks.');
    }
    return errors;
  };

  public render() {
    return (
      <StaticQuery
        query={graphql`
          {
            allFile(
              filter: {
                sourceInstanceName: { eq: "python_api" }
                base: { eq: "salvus_flow_schemas.json" }
              }
            ) {
              edges {
                node {
                  relativeDirectory
                  fields {
                    fileContent
                  }
                }
              }
            }
          }
        `}
        render={data => {
          const versionNumber = versionNumberFromDocPath(this.props.pathname);
          // Retrieve the available Salvus versions from the available Schema
          // files.
          let allSalvusVersions = data.allFile.edges.map(x => {
            let version = x.node.relativeDirectory.split('/')[0];
            return {
              key: version,
              text: version,
              value: version,
            };
          });

          let defaultVersionNumber =
            allSalvusVersions[
              Math.max(
                allSalvusVersions.map(x => x.key).indexOf(versionNumber),
                0
              )
            ].key;

          // Complicated but hopefully proper version number sorting with all
          // non-deployed version numbers coming at the end.
          allSalvusVersions.sort((a, b) => {
            if (isNaN(a.value.split('.').slice('-1'))) {
              return 1;
            }
            if (isNaN(b.value.split('.').slice('-1'))) {
              return -1;
            }
            return b.value.localeCompare(a.value, undefined, {
              numeric: true,
            });
          });

          // The currently selected version is either from the state or some
          // default.
          let salvus_version =
            this.state.salvus_version !== undefined
              ? this.state.salvus_version
              : defaultVersionNumber;

          // Now use version to get all data for this version.
          let d = JSON.parse(
            data.allFile.edges.filter(
              n => n.node.relativeDirectory.split('/')[0] == salvus_version
            )[0].node.fields.fileContent
          );

          // Extract the available site types from the data.
          let site_types = d.sites.map(s => {
            return {
              key: s.site_type,
              text: s.site_type,
              value: s.site_type,
            };
          });

          let site_type =
            this.state.site_type !== undefined
              ? this.state.site_type
              : site_types[0].key;

          // The state might already have a site type that does not exist for
          // another Salvus version - if that is the case just set it to the
          // first site type available for that Salvus version.
          if (!site_types.filter(s => s.key === site_type).length) {
            site_type = site_types[0].key;
          }

          let schema = d.sites.filter(s => s.site_type === site_type)[0].schema;

          let form = (
            <Form
              schema={schema}
              formData={this.state.form_data}
              onChange={this.onChange}
              omitExtraData={true}
              liveOmit={true}
              liveValidate={true}
              validate={this.customValidate}
            >
              <div></div>
            </Form>
          );

          const tabPanes = [
            {
              menuItem: 'Config Builder',
              render: () => (
                <div
                  css={{
                    // Get rid of the default field set border.
                    fieldset: { borderWidth: '0px' },
                    marginTop: '1em',
                  }}
                >
                  {form}
                </div>
              ),
            },
            {
              menuItem: 'TOML',
              render: () => {
                let site_config = { sites: {} };
                site_config.sites[
                  this.state.example_site_name
                ] = this.state.form_data;
                return (
                  <div
                    css={{
                      marginTop: '1em',
                    }}
                  >
                    <SUIForm>
                      <SUIForm.Field inline>
                        <label>Site name</label>
                        <Input
                          placeholder="Site name"
                          value={this.state.example_site_name}
                          onChange={(e, d) =>
                            this.setState({
                              ...this.state,
                              example_site_name: d.value,
                            })
                          }
                        />
                      </SUIForm.Field>
                    </SUIForm>

                    <Highlighter css={{ fontSize: '80%' }} language="toml">
                      {TOML.stringify(site_config)}
                    </Highlighter>
                  </div>
                );
              },
            },
          ];

          return (
            <div>
              This interactive configuration builder helps you to create a valid
              Salvus configuration file. Please see the{' '}
              <Link to="/docs/installation/salvus_flow_configuration">
                Salvus Flow Configuration
              </Link>{' '}
              page for more detailed explanations.
              <div css={{ paddingTop: '1ex' }}>
                Make sure to choose the correct Salvus release and site type and
                fill in the form. To finish the configuration switch to the TOML
                tab and copy/paste the configuration to your own configuration
                file.
              </div>
              <div css={{ paddingTop: '1ex' }}>
                Create configuration for Salvus version{' '}
                <span css={{ color: '#E49254' }}>
                  <Dropdown
                    inline
                    defaultValue={salvus_version}
                    options={allSalvusVersions}
                    onChange={(_, data) =>
                      this.setState({
                        ...this.state,
                        // Reset form data.
                        form_data: {},
                        salvus_version: data.value as string,
                        // Also reset tab index.
                        active_tab_index: 0,
                      })
                    }
                  />
                </span>{' '}
                and site type{' '}
                <span css={{ color: '#E49254' }}>
                  <Dropdown
                    inline
                    options={site_types}
                    value={site_type}
                    onChange={(_, data) => {
                      this.setState({
                        ...this.state,
                        // Reset form data.
                        form_data: {},
                        site_type: data.value as string,
                        // Also reset tab index.
                        active_tab_index: 0,
                      });
                    }}
                  />
                </span>
              </div>
              <Tab
                css={{ marginTop: '1em' }}
                activeIndex={this.state.active_tab_index}
                onTabChange={(e, d) =>
                  this.setState({
                    ...this.state,
                    active_tab_index: d.activeIndex,
                  })
                }
                panes={tabPanes}
              />
            </div>
          );
        }}
      />
    );
  }
}
