import React from 'react';
import { Checkbox } from 'semantic-ui-react';
import { WidgetProps } from 'react-jsonschema-form';

import InputWrapper from '../InputWrapper';

const CheckboxWidget = (props: WidgetProps) => {
  const { value, required, label, onChange, schema } = props;

  const _onChange = (_: any, data: { checked: boolean }) => {
    onChange(data.checked);
  };

  return (
    <InputWrapper
      label={label || schema.title}
      labelWidth="200px"
      description={schema['description']}
      required={required}
      inputWidget={
        <Checkbox toggle checked={value ? value : false} onChange={_onChange} />
      }
    ></InputWrapper>
  );
};

export default CheckboxWidget;
