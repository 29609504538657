import slug from 'slug';
import * as React from 'react';

import { Header } from 'semantic-ui-react';

/**
 * Get a string representing the header. Usually these are strings or a list of
 * <code> objects.
 * @param content Child nodes.
 */
function GetString(content: any): string {
  let output = '';
  if (typeof content === 'string') {
    output += content;
  } else if (typeof content === 'undefined') {
    return output;
  } else if (typeof content === 'object') {
    if (Array.isArray(content)) {
      for (let i of content) {
        output += GetString(i);
      }
    } else {
      if (!('props' in content)) {
        throw 'Could not construct header as no props in content.';
      }
      output += GetString(content.props.children);
    }
  } else {
    throw `Unknown content for header: ${content}`;
  }
  return output;
}

interface HeaderProps {
  className?: string;
  pre?: React.ReactElement;
  children: any;
}

function getHeader(
  props: HeaderProps,
  header_type: string
): React.ReactElement {
  let link = slug(GetString(props.children), { lower: true });

  let extra_config = {
    h1: {
      // No underline for H1.
      css: {
        '&:nth-of-type(1n+2)': { paddingTop: '0.75em !important' },
        backgroundImage: 'unset',
      },
    },
    h2: {
      underLineSize: '100% 1px',
      opacity: 0.4,
      css: {
        marginTop: '0.1em !important',
        paddingTop: '0.75em !important',
      },
    },
    h3: {
      underLineSize: '100% 1px',
      opacity: 0.2,
      css: {
        fontWeight: '400 !important',
        paddingTop: '0.25em !important',
        marginTop: '0.1em !important',
      },
    },
    h4: {
      underLineSize: '100% 1px',
      opacity: 0.15,
      css: {
        paddingTop: '0.25em !important',
        marginTop: '0.1em !important',
      },
    },
    h5: {
      underLineSize: '100% 1px',
      opacity: 0.1,
      css: {
        paddingTop: '0.25em !important',
        marginTop: '0.1em !important',
      },
    },
    h6: {
      underLineSize: '100% 1px',
      opacity: 0.1,
      css: {
        paddingTop: '0.25em !important',
        marginTop: '0.1em !important',
      },
    },
  };

  let extra = extra_config[header_type];

  return (
    <Header
      as={header_type}
      className={props.className}
      css={{
        marginBottom: '0px !important',
        paddingBottom: '14px !important',
        backgroundImage: `linear-gradient(
              to right,
              rgba(255, 201, 18, ${extra.opacity}),
              rgba(201, 0, 197, ${extra.opacity}),
              rgba(0, 88, 200, ${extra.opacity}))`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: extra.underLineSize,
        backgroundPosition: '0 calc(100% - 10px)',
        backgroundClip: 'padding-box',
        ...extra.css,
      }}
    >
      {/* An empty block to get anchor links that also account for the height of
        the global navigation bar. */}
      <a
        id={link}
        style={{
          display: `block`,
          position: `relative`,
          // Don't use relative units here as this is for different header sizes.
          top: `-50px`,
          visibility: `hidden`,
        }}
      />
      {props.pre && <span>{props.pre}</span>}
      <a href={`#${link}`} style={{ color: `unset` }}>
        {props.children}
      </a>
    </Header>
  );
}

class H1 extends React.PureComponent<HeaderProps> {
  render() {
    return getHeader(this.props, 'h1');
  }
}

class H2 extends React.PureComponent<HeaderProps> {
  render() {
    return getHeader(this.props, 'h2');
  }
}

class H3 extends React.PureComponent<HeaderProps> {
  render() {
    return getHeader(this.props, 'h3');
  }
}

class H4 extends React.PureComponent<HeaderProps> {
  render() {
    return getHeader(this.props, 'h4');
  }
}

class H5 extends React.PureComponent<HeaderProps> {
  render() {
    return getHeader(this.props, 'h5');
  }
}

class H6 extends React.PureComponent<HeaderProps> {
  render() {
    return getHeader(this.props, 'h6');
  }
}

export { H1, H2, H3, H4, H5, H6 };
