import React from 'react';

import InputWrapper from '../InputWrapper';

import { Input } from 'semantic-ui-react';

import { WidgetProps } from 'react-jsonschema-form';

const TextWidget = ({
  required,
  label,
  value,
  onChange,
  options,
  schema,
}: WidgetProps) => {
  const _onChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) =>
    onChange(value === '' ? options.emptyValue : value);
  let labelWidth = '200px';
  // This is a bit hacky but this essentially detects the contents of arrays.
  // Thus we can force a smaller label width here and everything looks nice.
  if (JSON.stringify(schema) == JSON.stringify({ type: 'string' })) {
    labelWidth = '80px';
  }

  return (
    <InputWrapper
      label={label || schema.title}
      labelWidth={labelWidth}
      description={schema['description']}
      required={required}
      inputWidget={
        <Input
          css={{ width: '100%' }}
          transparent
          value={value ? value : ''}
          onChange={_onChange}
        />
      }
    ></InputWrapper>
  );
};

export default TextWidget;
