import * as React from 'react';

import { Icon, Label, Segment } from 'semantic-ui-react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { default as colorTheme } from './prism_color_schemes/okaidia_modified';
import { default as colorThemeLight } from './prism_color_schemes/one_light';
// import { default as colorThemeLight } from './prism_color_schemes/solarized_light';
import Clipboard from 'react-clipboard.js';

class Highlighter extends React.Component<
  {
    children: string;
    language: string;
    className: string;
    fontSize?: string;
    omitClipboard?: string;
    lightTheme?: boolean
  },
  { copied: boolean }
> {
  label: any;

  constructor(props: any) {
    super(props);
    this.state = {
      copied: false,
    };
  }

  onClick(event) {
    event.preventDefault();
  }

  onSuccessfulCopy = () => {
    this.setState({
      copied: true,
    });
    setTimeout(() => this.setState({ copied: false }), 1000);
  };

  public render() {
    let fontSize = '80%';
    if (this.props.fontSize !== undefined) {
      fontSize = this.props.fontSize;
    }

    let language: string = this.props.language
      ? this.props.language
      : this.props.className;

    if (language && language.startsWith('language')) {
      language = language.slice(9);
    }

    this.label = (
      <Label
        color={!this.state.copied ? 'orange' : 'purple'}
        ribbon="right"
        size="tiny"
        // Account for the missing padding on the Segment.
        css={{
          left: 'calc(100% + 1rem + 1.2em - 14px) !important',
          position: 'absolute !important',
          top: '0.3em',
        }}
        as="a"
        onClick={this.onClick}
      >
        <Icon name="copy outline" />
        {!this.state.copied ? 'Copy' : 'Copied to clipboard'}
      </Label>
    );
    let children = this.props.children.trim();

    let style = colorTheme;
    if (this.props.lightTheme == true) {
      style = colorThemeLight;
    }


    return (
      <Segment
        css={{
          borderWidth: '0px !important',
          padding: '0px !important',
          fontSize: fontSize,
          boxShadow: '1px 1px 3px 1px rgba(0,0,0,0.48) !important',
        }}
      >
        {(this.props.omitClipboard === true) ||
          <Clipboard
            component="div"
            data-clipboard-text={children}
            onSuccess={this.onSuccessfulCopy}
          >
            {this.label}
          </Clipboard>}
        <SyntaxHighlighter language={language} style={style}>
          {children}
        </SyntaxHighlighter>
      </Segment>
    );
  }
}

export default Highlighter;
