import { Grid, Image, Segment } from 'semantic-ui-react';
import { SalvusCompute, SalvusMesh, SalvusOpt, SalvusFlow } from "../../../../src/components/doc/names";
import * as React from 'react';
export default {
  Grid,
  Image,
  Segment,
  SalvusCompute,
  SalvusMesh,
  SalvusOpt,
  SalvusFlow,
  React
};