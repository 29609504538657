import React from 'react';

import { FieldTemplateProps } from 'react-jsonschema-form';

const FieldTemplate = ({ rawErrors, children }: FieldTemplateProps) => {
  let css;
  if (rawErrors && rawErrors.length > 0) {
    css = {
      backgroundColor: '#FFE8E6',
      boxShadow:
        '0px 0px 0px 1px #DB2828 inset, 0px 0px 0px 0px rgba(0, 0, 0, 0)',
    };
  } else {
    css = {};
  }
  return <div css={css}>{children}</div>;
};

export default FieldTemplate;
