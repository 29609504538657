import React from 'react';

import {
  isMultiSelect,
  getDefaultRegistry,
} from 'react-jsonschema-form/lib/utils';

import InputWrapper from '../InputWrapper';

import { ArrayFieldTemplateProps } from 'react-jsonschema-form';

import AddButton from '../AddButton/AddButton';
import IconButton from '../IconButton/IconButton';

const ArrayFieldTemplate = (props: ArrayFieldTemplateProps) => {
  const { schema, registry = getDefaultRegistry() } = props;

  if (isMultiSelect(schema, registry.definitions)) {
    return <div>MULTI NOT YET IMPLEMENTED!!!!</div>;
  } else {
    return <DefaultNormalArrayFieldTemplate {...props} />;
  }
};

const DefaultArrayItem = (props: any) => {
  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div css={{ width: '100%' }}>{props.children}</div>

      {props.hasToolbar && (
        <div css={{ paddingLeft: '1em', minWidth: '150px' }}>
          {(props.hasMoveUp || props.hasMoveDown) && (
            <IconButton
              icon="arrow up"
              disabled={props.disabled || props.readonly || !props.hasMoveUp}
              onClick={props.onReorderClick(props.index, props.index - 1)}
              color="orange"
            />
          )}

          {(props.hasMoveUp || props.hasMoveDown) && (
            <IconButton
              icon="arrow down"
              disabled={props.disabled || props.readonly || !props.hasMoveDown}
              onClick={props.onReorderClick(props.index, props.index + 1)}
              color="orange"
            />
          )}

          {props.hasRemove && (
            <IconButton
              icon="remove"
              disabled={props.disabled || props.readonly}
              onClick={props.onDropIndexClick(props.index)}
              color="red"
            />
          )}
        </div>
      )}
    </div>
  );
};

const DefaultNormalArrayFieldTemplate = (props: ArrayFieldTemplateProps) => {
  let content = (
    <div css={{ marginBottom: '0.3em' }}>
      {props.items && props.items.map(p => DefaultArrayItem(p))}

      {props.canAdd && (
        <div>
          <AddButton
            onClick={props.onAddClick}
            disabled={props.disabled || props.readonly}
          />
        </div>
      )}
    </div>
  );

  return (
    <InputWrapper
      label={props.title}
      labelWidth="200px"
      description={props.schema.description || ''}
      required={props.required}
      inputWidget={content}
    />
  );
};

export default ArrayFieldTemplate;
