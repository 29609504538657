import React from 'react';

const InputWrapper = (props: {
  label: string;
  labelWidth: string;
  description: string;
  inputWidget: any;
  required: boolean;
}) => {
  const { label, description, inputWidget, required } = props;

  let labelWidth = props.labelWidth;
  if (!props.label) {
    labelWidth = '0px';
  }

  return (
    <div css={{ display: 'flex' }}>
      <label
        css={{
          fontSize: '80%',
          minWidth: labelWidth,
          textAlign: 'right',
          paddingRight: '1em',
        }}
      >
        <div css={{ color: 'orange' }}>{label}</div>

        {props.required || <div css={{ color: 'purple' }}>[optional]</div>}
      </label>
      <div css={{ flexGrow: '10' }}>
        {inputWidget}
        <div
          css={{
            fontSize: '70%',
            borderTop: '1px solid rgba(0, 0, 0, 0.42)',
            color: 'rgba(0, 0, 0, 0.40)',
            paddingBottom: '1em',
            lineHeight: '1.4em',
          }}
        >
          {description}
        </div>
      </div>
    </div>
  );
};

export default InputWrapper;
