import { useStaticQuery, graphql } from 'gatsby';

////////////////////////////////////////
// XXX: This function is also defined in ../gatsby-config.js
// If you modify it here, also modify it there!
/**
 * Sort the version numbers into an object of `current` and `others`. Others
 * should be sorted new to old.
 *
 * @param versionNumbers List of all available version numbers.
 */

function sortVersionNumbers(
  versionNumbers: string[]
): { current: string; others: string[] } {
  versionNumbers.sort((a, b) => {
    if (isNaN(a.split('.').slice(-1))) {
      return 1;
    }
    if (isNaN(b.split('.').slice(-1))) {
      return -1;
    }
    return b.localeCompare(a, undefined, { numeric: true });
  });
  return { current: versionNumbers[0], others: versionNumbers.slice(1) };
}

function versionNumberFromDocPath(path: string): string {
  if (!path.startsWith('/doc')) {
    throw new Error(`Not a doc path: ${path}`);
  }

  // Root doc path.
  if (path === '/doc') {
    return 'LATEST';
  }

  // Don't know what's going on here.
  const splitPath = path.split('/');
  if (splitPath.length < 2) {
    throw new Error(`Unrecognized doc path: ${path}`);
  }

  const versionNumber = splitPath[2];
  if (!/^\d{1,2}\.\d{1,2}\..*$/.test(versionNumber)) {
    return 'LATEST';
  }

  return versionNumber;
}

function getAvailableVersionNumbers(): { current: string; others: string[] } {
  return useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            salvusDocVersions {
              current
              others
            }
          }
        }
      }
    `
  ).site.siteMetadata.salvusDocVersions;
}

function hexToRgb(hex: string): { r: number; g: number; b: number } | null {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    }
    : null;
}

export {
  hexToRgb,
  sortVersionNumbers,
  versionNumberFromDocPath,
  getAvailableVersionNumbers,
};
