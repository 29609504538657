import * as React from 'react';
import { remark } from 'remark';
import remark_math from 'remark-math-latest';
import remark2rehype from 'remark-rehype';
import rehype2react from 'rehype-react';
import rehypeKatex from 'rehype-katex';
import remarkGfm from 'remark-gfm';

import { Image, Table } from 'semantic-ui-react';

import { H1, H2, H3, H4, H5, H6 } from '../components/mdx_blocks/header';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Highlighter from '../components/mdx_blocks/highlighter';

interface ChildProps {
  children: any;
  src?: string;
}

const PaddedMarkdownParagraph = (props: ChildProps) => {
  return (
    <div
      css={{
        ':not(:last-child)': { marginBottom: '16px' },
      }}
    >
      {props.children}
    </div>
  );
};

const PaddedH1 = (props: ChildProps) => {
  return (
    <div style={{ marginBottom: '1.5em' }}>
      <H1>{props.children}</H1>
    </div>
  );
};

const PaddedH2 = (props: ChildProps) => {
  return (
    <div style={{ marginTop: `1em`, marginBottom: '1.0em' }}>
      <H2>{props.children}</H2>
    </div>
  );
};

const PaddedH3 = (props: ChildProps) => {
  return (
    <div style={{ marginTop: `1em`, marginBottom: '1.0em' }}>
      <H3>{props.children}</H3>
    </div>
  );
};

const MarkdownImage = (props: ChildProps) => {
  // Convert to an absolute path to not depend on trailing
  // slashes.
  let imagePath = props.src;

  if (imagePath && !imagePath.startsWith('/') && props.pathname) {
    let orgPath = imagePath.split('/').filter((i) => i != '.');
    let path = props.pathname
      .split('/')
      .filter((i) => i)
      .slice(0, -1);

    imagePath = '/' + path.concat(orgPath).join('/');
  }

  // This image syntax for example will render an image with a width of 50%:
  // ![|50%](image.png)
  let width = "100%";
  if (props.alt !== undefined) {
    let match = props.alt.match(/^.*\|(.*)$/);
    if (match) {
      width = match[1];
    }
  }

  return <Image width={width} centered src={imagePath} />;
};

const Code = (props) => {
  if (props.className === undefined && props.children[0].match(/\n/gm) === null) {
    return <code>{props.children}</code>;
  }
  return (
    <Highlighter
      language={props.className}
      fontSize="85% !important"
      lightTheme={true}
      omitClipboard={true}>
      {props.children.join('\n')}
    </Highlighter>
  );
};

function renderMarkdown(contents: string, pathname: string) {
  return remark()
    .use(remarkGfm)
    .use(remark_math)
    .use(remark2rehype)
    .use(rehypeKatex)
    .use(rehype2react, {
      createElement: React.createElement,
      components: {
        code: Code,
        p: PaddedMarkdownParagraph,
        h1: PaddedH1,
        h2: PaddedH2,
        h3: PaddedH3,
        h4: H4,
        h5: H5,
        h6: H6,
        // Map all table tags to the semantic ui variant.
        table: Table,
        thead: Table.Header,
        tbody: Table.Body,
        tr: Table.Row,
        td: Table.Cell,
        th: Table.HeaderCell,
        // Simple image wrapper.
        img: (props) => <MarkdownImage pathname={pathname} {...props} />,
      },
    })
    .processSync(contents).result;
}

class MarkdownImagePythonAPI extends React.Component<{ src: string }, {}> {
  public render() {
    return (
      <StaticQuery
        query={graphql`
          {
            allFile(
              filter: {
                relativeDirectory: { glob: "*/python_api/images" }
                sourceInstanceName: { eq: "python_api" }
                extension: { eq: "png" }
              }
            ) {
              edges {
                node {
                  base
                  childImageSharp {
                    gatsbyImageData(width: 850, placeholder: TRACED_SVG)
                  }
                }
              }
            }
          }
        `}
        render={(data) => {
          let n = data.allFile.edges.filter(
            (i) => i.node.base === this.props.src
          );
          // There might be multiple versions of the same image here. We only
          // need one though.
          if (n.length == 0) {
            console.log(
              `Could not find image ${this.props.src}. Rerun doc generation?`
            );
            console.log(data);
            console.log(n);
          }

          return (
            <GatsbyImage
              image={n[0].node.childImageSharp.gatsbyImageData}
              alt=""
            />
          );
        }}
      />
    );
  }
}

/// Specialized render function that has a special image component that points to
/// the correct images.
function renderMarkdownPythonAPI(contents: string) {
  return remark()
    .use(remarkGfm)
    .use(remark_math)
    .use(remark2rehype)
    .use(rehypeKatex)
    .use(rehype2react, {
      createElement: React.createElement,
      components: {
        code: Code,
        p: PaddedMarkdownParagraph,
        h1: PaddedH1,
        h2: PaddedH2,
        h3: PaddedH3,
        h4: H4,
        h5: H5,
        h6: H6,
        img: MarkdownImagePythonAPI,
      },
    })
    .processSync(contents).result;
}

export {
  renderMarkdown,
  renderMarkdownPythonAPI,
  PaddedH1,
  PaddedH2,
  PaddedH3,
};
